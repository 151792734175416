import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular/standalone';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  counter = 0;
  loading: Promise<HTMLIonLoadingElement> | undefined;
  constructor(private loadingCtrl: LoadingController) {}

  async show(backdropDismiss = true) {
    // console.log('eeee show');
    try {
      this.counter++;
      if (this.loading) {
        throw new Error('LoaderService: another loading is running');
      }
      this.loading = this.loadingCtrl.create({
        message: 'Loading...',
        backdropDismiss,
      });
      (await this.loading).present();
    } catch (error) {
      console.info(error.message);
    }
  }

  async hide() {
    // console.log('eeee hide', this.counter);
    try {
      if (this.counter > 0) {
        this.counter--;
      }
      if (this.counter === 0 && this.loading) {
        await (await this.loading).dismiss();
        delete this.loading;
      }
    } catch (error) {
      console.info(error.message);
    }
  }
}
